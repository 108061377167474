.testing-container-test {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

/* Section 1 Styling */
.section1-test {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
}

.image-container1-test {
    width: 100%;
}

.image1-test {
    width: 100%;
    height: 500px; /* Reduced height for desktop */
    border-radius: 8px;
}

.text-container1-test {
    padding: 20px;
    text-align: center;
}

.title1-test {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.description1-test,
.additional-info1-test {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

/* Section 2 Styling */
.section2-test {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    align-items: center;
}

.text-container2-test {
    flex: 1;
    padding: 20px;
}

.image-container2-test {
    flex: 1;
    padding: 20px;
}

.image2-test {
    width: 100%;
    height: 350px; /* Adjusted height for better responsiveness */
    border-radius: 8px;
}

.sub-title2-test {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.description2-test,
.additional-info2-test {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

/* Section 3 Styling */
.section3-test {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    align-items: center;
    flex-direction: row-reverse;
}

.text-container3-test {
    flex: 1;
    padding: 20px;
}

.image-container3-test {
    flex: 1;
    padding: 20px;
}

.image3-test {
    width: 100%;
    height: 350px; /* Adjusted height for better responsiveness */
    border-radius: 8px;
}

.sub-title3-test {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.description3-test,
.additional-info3-test {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

/* Section 4 Styling */
.section4-test {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    align-items: center;
}

.text-container4-test {
    flex: 1;
    padding: 20px;
}

.image-container4-test {
    flex: 1;
    padding: 20px;
}

.image4-test {
    width: 100%;
    height: 350px; /* Adjusted height for better responsiveness */
    border-radius: 8px;
}

.sub-title4-test {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.description4-test,
.additional-info4-test {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

.bluetest {
    color: #007BFF;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .image1-test {
        height: 400px; /* Lower the height for tablets */
    }
    .image2-test,
    .image3-test,
    .image4-test {
        height: 300px; /* Lower the height for tablets */
    }

    .title1-test {
        font-size: 2rem;
    }

    .sub-title2-test,
    .sub-title3-test,
    .sub-title4-test {
        font-size: 1.8rem;
    }

    .description1-test,
    .description2-test,
    .description3-test,
    .description4-test,
    .additional-info1-test,
    .additional-info2-test,
    .additional-info3-test,
    .additional-info4-test {
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .section1-test,
    .section2-test,
    .section3-test,
    .section4-test {
        flex-direction: column;
    }

    .image1-test {
        height: 300px; /* Further reduce height for mobile */
    }

    .image2-test,
    .image3-test,
    .image4-test {
        height: 250px; /* Further reduce height for mobile */
    }

    .title1-test {
        font-size: 1.8rem;
    }

    .sub-title2-test,
    .sub-title3-test,
    .sub-title4-test {
        font-size: 1.6rem;
    }

    .description1-test,
    .description2-test,
    .description3-test,
    .description4-test,
    .additional-info1-test,
    .additional-info2-test,
    .additional-info3-test,
    .additional-info4-test {
        font-size: 0.9rem;
    }
}
