/* Consultation Section */
.sap55-consultation-section {
    position: relative;
    height: 400px;
    margin: 0 10px; /* Add gap on left and right */
}

.sap55-consultation-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sap55-consultation-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
}

.sap55-consultation-heading {
    font-size: 2rem;
    font-weight: bold;
}

.sap55-consultation-subheading {
    font-size: 1.5rem;
    margin: 20px 0;
}

.sap55-consultation-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .sap55-consultation-section {
        height: 350px; /* Allow height to adjust based on content */
        margin: 0 5px; /* Reduce margin on smaller screens */
      
    }

    .sap55-consultation-heading {
        font-size: 1.5rem; /* Reduce font size on smaller screens */
    }

    .sap55-consultation-subheading {
        font-size: 1.2rem; /* Reduce font size on smaller screens */
    }

    .sap55-consultation-link {
        padding: 8px 16px; /* Adjust padding on smaller screens */
    }
}

@media (max-width: 480px) {
    .sap55-consultation-heading {
        font-size: 1.2rem; /* Further reduce font size on very small screens */
    }

    .sap55-consultation-subheading {
        font-size: 1rem; /* Further reduce font size on very small screens */
    }

    .sap55-consultation-link {
        padding: 6px 12px; /* Adjust padding on very small screens */
    }
}
