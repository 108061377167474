/* General Container Styling */
.contactus-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Content Styling */
  .contactus-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  
  /* Image Styling */
  .contactus-img {
    flex: 1;
    padding: 20px;
  }
  
  .contactus-logo {
    width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
  }
  
  /* Details Styling */
  .contactus-details {
    flex: 1;
    padding: 20px;
    text-align: left;
  }
  
  .contactus-heading {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .contactus-address,
  .contactus-email {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  .contactus-email-link {
    color: #007bff;
    text-decoration: none;
  }
  
  .contactus-email-link:hover {
    text-decoration: underline;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .contactus-content {
      flex-direction: column;
      text-align: center;
    }
    
    .contactus-img,
    .contactus-details {
      padding: 10px;
    }
  }
  