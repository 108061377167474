.services-containerServ {
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

.services-headingServ {
  font-size: 36px;
  font-weight: bold;
  color: #1968d7;
  text-align: center;
  margin-bottom: 20px;
}

.services-textServ {
  font-size: 18px;
  color: #555;
  text-align: center;
  margin-bottom: 10px;
}

.services-images-containerServ {
  display: flex;
  justify-content: space-between;
  align-items: stretch; /* Ensure all images have the same height */
  gap: 20px;
  margin-top: 40px;
}

.services-imageServ {
  position: relative;
  border-radius: 10px;
  flex: 1;
  height: 350px; /* Set a consistent height for both images */
  overflow: hidden;
}

.imageServ {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.overlayServ {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  padding: 10px;
  text-align: center;
  border-radius: 0 0 10px 10px;
}

.overlay-titleServ {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin: 0;
}

.overlay-subtitleServ {
  font-size: 16px;
  color: #fff;
  margin: 0;
}

@media (max-width: 768px) {
  .services-images-containerServ {
    flex-direction: column;
  }

  .services-imageServ {
    margin-bottom: 20px;
  }

  .imageServ {
    height: 250px; /* Adjust image height for smaller screens */
  }
}
