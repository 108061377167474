.footer-containerDevFtr {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #070707;
  color: #fff;
  flex-wrap: wrap;
}

.footer-columnDevFtr {
  flex: 1;
  min-width: 200px;
  margin: 10px;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.footer-logoDevFtr {
  max-height: 100px;
  margin-bottom: -25px; 
  border-radius: 5px;
  width: 80px;
  /* margin-top: -5px; */
  
  
}

.footer-logoDevFtr12 {
  max-height: 130px;
  margin-bottom: 20px;
  border-radius: 5px;
  width: 170px;
  margin-top: -30px;
}

.footer-textDevFtr {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 10px;
}

.footer-headingDevFtr {
  font-size: 18px;
  margin-bottom: 45px; /* Reduce bottom margin */
  text-transform: uppercase;
  color: #fff;
}

.options25{
  color: #fff;
}

.footer-services-listDevFtr,
.footer-tech-listDevFtr {
  list-style: none;
  padding: 0;
  font-size: 16px; /* Increase font size for better readability */
}

.footer-services-listDevFtr li,
.footer-tech-listDevFtr li {
  margin-bottom: 14px; /* Reduce bottom margin */
}

.footer-addressDevFtr {
  font-size: 16px; /* Increase font size for better readability */
  margin-bottom: -10px; /* Reduce bottom margin */
  margin-top: -40px;
}

.footer-location-iconDevFtr,
.footer-message-iconDevFtr {
  margin-right: 5px;
}

.footer-emailDevFtr {
  font-size: 16px; /* Increase font size for better readability */
}

/* Responsive Styles for Mobile */
@media (max-width: 768px) {
  .footer-containerDevFtr {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px; /* Reduce padding for mobile */
  }

  .footer-columnDevFtr {
    width: 100%; /* Ensure each column takes full width */
    margin-bottom: 15px; /* Increase space between columns */
  }

  .footer-logoDevFtr{
    width: auto; /* Allow logos to resize naturally on smaller screens */
    margin-bottom: -10px;
  }

  .footer-logoDevFtr12 {
    width: auto; /* Allow logos to resize naturally on smaller screens */
    margin-bottom: 20px;
  }

  .footer-headingDevFtr {
    font-size: 16px; /* Adjust font size for smaller screens */
    margin-bottom: 20px; /* Reduce bottom margin for mobile */
  }

  .footer-textDevFtr,
  .footer-services-listDevFtr li,
  .footer-tech-listDevFtr li,
  .footer-addressDevFtr,
  .footer-emailDevFtr {
    font-size: 14px; /* Adjust font size for better readability on mobile */
    margin-bottom: 10px; /* Uniform margin for better spacing on mobile */
  }
}
