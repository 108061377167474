/* Container */
.car88-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin: 0 auto;
    max-width: 1200px;
  }
  
  /* Description Section */
  .car88-description {
    flex: 1;
    padding-right: 20px;
  }
  
  .car88-heading {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .car88-text {
    font-size: 1.2rem;
    line-height: 1.6;
  }
  
  /* Image Section */
  .car88-image-section {
    flex: 1;
    text-align: right;
  }
  
  .car88-image {
    width: 100%;
    height: auto;
    max-width: 500px;
    object-fit: cover;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .car88-container {
      flex-direction: column;
      text-align: center;
    }
  
    .car88-description {
      padding-right: 0;
      margin-bottom: 20px;
    }
  
    .car88-image-section {
      text-align: center;
    }
  
    .car88-image {
      max-width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .car88-heading {
      font-size: 1.5rem;
    }
  
    .car88-text {
      font-size: 1rem;
    }
  }
  