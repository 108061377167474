.consulting-containerConc {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Section 1 Styling */
  .section1Conc {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }
  
  .image-container1Conc {
    width: 100%;
  }
  
  .image1Conc {
    width: 100%;
    height:700px;
    border-radius: 8px;
  }
  
  .text-container1Conc {
    padding: 20px;
    text-align: center;
  }
  
  .title1Conc {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .description1Conc,
  .additional-info1Conc {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  /* Section 2 Styling */
  .section2Conc {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    align-items: center;
  }
  
  .text-container2Conc {
    flex: 1;
    padding: 20px;
  }
  
  .image-container2Conc {
    flex: 1;
    padding: 20px;
  }
  
  .image2Conc {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .sub-title2Conc {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .description2Conc,
  .additional-info2Conc {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  /* Section 3 Styling */
  .section3Conc {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    align-items: center;
    flex-direction: row-reverse;
  }
  
  .text-container3Conc {
    flex: 1;
    padding: 20px;
  }
  
  .image-container3Conc {
    flex: 1;
    padding: 20px;
  }
  
  .image3Conc {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .sub-title3Conc {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .description3Conc,
  .additional-info3Conc {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  .blueConc {
    color: #007bff;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .section2Conc,
    .section3Conc {
      flex-direction: column;
    }
  }
  


  @media (max-width: 768px) {
    .image1Conc {
      height: 350px; /* Further lower the height for smaller screens */
    }}
