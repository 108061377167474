/* General Container */
.containerTrs {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Homepage Image Section */
.homepage-image-sectionTrs {
  margin-bottom: 20px;
}

.homepage-imageTrs {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Introduction Section */
.intro-sectionTrs {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.main-headingTrs {
  font-size: 36px;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  margin: 20px 0;
}

@media (min-width: 768px) {
  .main-headingTrs {
    font-size: 40px;
    text-align: left;
    margin-left: 70px;
  }
}

.black-textTrs {
  color: #000;
}

.blue-textTrs {
  color: #007bff;
}

/* Content Section */
.content-sectionTrs {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  text-align: center;
}

@media (min-width: 768px) {
  .content-sectionTrs {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    gap: 20px; /* Add spacing between the text and image */
  }
}

.left-textTrs {
  font-size: 18px;
  color: #333;
  line-height: 1.5;
  margin-bottom: 20px;
  flex: 1; /* Allow text to take up available space */
}

@media (min-width: 768px) {
  .left-textTrs {
    font-size: 20px;
  }
}

.image-sectionTrs {
  width: 100%;
  max-width: 400px; /* Restrict the image width */
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .image-sectionTrs {
    margin-bottom: 0;
  }
}

.imageTrs {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

/* SAP Section */
.sap-sectionTrs {
  margin-top: 20px;
  text-align: center;
}

.sap-headingTrs {
  font-size: 22px;
  font-weight: bold;
}

@media (min-width: 768px) {
  .sap-headingTrs {
    font-size: 24px;
  }
}

.subheadingTrs {
  font-size: 14px;
  color: #555;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .subheadingTrs {
    font-size: 16px;
  }
}

/* Logos Row Section */
.logos-rowTrs {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #074d97;
  padding: 20px;
}

@media (min-width: 768px) {
  .logos-rowTrs {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
  }
}

.logo-itemTrs {
  width: 100%;
  text-align: center;
  background-color: #186cc5;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 10px;
  transition: transform 0.3s, background-color 0.3s;
}

@media (min-width: 768px) {
  .logo-itemTrs {
    flex: 1;
    margin: 0 10px;
    margin-bottom: 0;
  }
}

.logoTrs {
  max-height: 50px;
  margin-bottom: 10px;
}

.logo-itemTrs p {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
}

.logo-itemTrs:hover {
  background-color: #0056b3;
  transform: translateY(-5px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .logos-rowTrs {
    padding: 10px;
  }

  .logo-itemTrs {
    margin: 10px 0;
  }
}
