/* General Styles */


/* Home Banner Image */
.cloud-banner {
  width: 100%;
  margin-bottom: 20px;
  height: 500px;
}

.cloud-banner-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
/* Cloud Computing Section */
.cloud-computing {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

/* Cloud Computing Text */
.cloud-computing-text {
  flex: 3;
  margin-right: 20px;
}

/* Cloud Computing Heading */
.cloud-computing-heading {
  font-size: 35px;
  margin-left: 100px;
}

/* Cloud Computing Blue Text */
.cloud-computing-blue {
  color: #0000FF;
}

/* Cloud Computing Description */
.cloud-computing-description {
  font-size: 20px;
  line-height: 1.5;
  margin: 50px;
}

/* Cloud Computing Image */
.cloud-computing-image {
  flex: 1;
  margin: 30px;
}

/* Cloud Computing Image Styling */
.cloud-computing-img {
  width: 100%;
  height: 250px;
  max-width: 100%;
  border-radius: 5px;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .cloud-computing {
    flex-direction: column; /* Stack text and image vertically */
    align-items: center; /* Center-align content */
    text-align: center; /* Center-align text content */
    margin: 20px; /* Adjust margin for mobile view */
  }

  .cloud-computing-text {
    margin-right: 0; /* Remove right margin on mobile */
    padding: 0 20px; /* Add padding for better text readability */
  }

  .cloud-computing-heading {
    font-size: 28px; /* Adjust font size for mobile */
    margin: 0 0 10px 0; /* Center-align heading with margin */
  }

  .cloud-computing-description {
    font-size: 18px; /* Slightly smaller text for mobile */
    margin: 10px 0; /* Adjust margin for mobile */
  }

  .cloud-computing-image {
    margin: 20px 0; /* Center image with margin on top and bottom */
  }

  .cloud-computing-img {
    width: 90%; /* Adjust image width for mobile */
    height: auto; /* Auto height to maintain aspect ratio */
  }
}


/* Cloud Services Section */
.cloud-services {
  margin-bottom: 20px;
  margin: 70px;
}

.cloud-services-heading {
  font-size: 24px;
  margin-bottom: 20px;
}

.cloud-services-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.cloud-card {
  flex: 1 1 calc(50% - 20px); /* Two cards per row with a gap */
  box-sizing: border-box;
  border: 1px solid #ddd; /* Optional: border for card */
  border-radius: 8px; /* Optional: rounded corners */
  overflow: hidden; /* Ensure content does not overflow */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align items */
}

.cloud-card-img {
  width: 100%;
  height: 350px;
}

.cloud-card-text {
  padding: 10px;
  text-align: center; /* Center align text */
}

.cloud-card-text h4 {
  margin-top: 0;
}

.cloud-card-button {
  background-color: #23234a; /* Blue color */
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-top: 20px;
  
}

.cloud-card-button:hover {
  background-color: #0056b3; /* Darker blue */
}

/* Responsive Design */
@media (max-width: 768px) {
  .cloud-computing {
    flex-direction: column;
    align-items: flex-start;
  }

  .cloud-computing-image {
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .cloud-services-cards {
    flex-direction: column;
    width: 350px;
    margin-left: -30px;
  }
}



@media (max-width: 480px) {
  .cloud-banner {
    height: 400px;
  }
}
