/* Container Styles */
.tstack-containerStack5 {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Header Section */
  .tstack-headerStack5 {
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .tstack-header-greenStack5 {
    color: green;
  }
  
  .tstack-subtextStack5 {
    text-align: center;
    font-size: 16px;
    margin-bottom: 40px;
  }
  
  /* Main Box Section */
  .tstack-main-boxStack5 {
    border: 2px solid #ccc;
    padding: 20px;
    border-radius: 10px;
  }
  
  /* Category Tabs */
  .tstack-categories-rowStack5 {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .tstack-categoryStack5 {
    flex: 1;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 0 10px;
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
  }
  
  .tstack-categoryStack5:hover {
    background-color: blue;
    color: white;
  }
  
  .tstack-activeStack5 {
    background-color: blue;
    color: white;
  }
  
  /* Images Display Section */
  .tstack-images-containerStack5 {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px 0;
  }
  
  .tstack-imageStack5 {
    display: inline-block;
    margin-right: 10px;
    height: auto;
    max-height: 150px; /* Adjust this value based on your preference */
    border-radius: 5px;
  }
  
  .tstack-imageStack5:last-child {
    margin-right: 0;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .tstack-categories-rowStack5 {
      flex-direction: column;
    }
  
    .tstack-categoryStack5 {
      margin: 10px 0;
      
    }
  
    .tstack-images-containerStack5 {
      padding: 0;
      
    }
  }
  
  @media (max-width: 480px) {
    .tstack-imageStack5 {
      max-height: 100px; /* Adjust this value based on your preference */
    }
  }
  

