/* General Styles */
.sap55-container {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* margin: 20px; */
}

/* Home Banner */
.sap55-banner {
    position: relative;
    width: calc(100% - 20px); /* Adjust for gap */

    height: 450px;
}

.sap55-banner-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}



/* Main Text Section */
.sap55-main-section {
    display: flex;
    padding: 20px;
    align-items: center;
    margin: 0 10px; /* Add gap on left and right */
}

.sap55-logo {
    flex: 0 0 35%; /* Take up 35% of the width */
    text-align: center;
}

.sap55-logo-img {
    width: 80%; /* Reduce the image width to fit better inside the 35% area */
    border-radius: 70%; /* Make the image circular */
    object-fit: cover; /* Ensure the image covers the circle shape */
    height: 300px;
}

.sap55-text-content {
    flex: 0 0 65%; /* Take up the remaining 65% of the width */
    margin-left: 20px;
}

.sap55-text {
    margin: 10px 0;
    line-height: 1.6;
}



/* SAP Section */
/* SAP Section */
.sap55-sap-section {
    display: flex;
    flex-direction: column; /* Stack items vertically on mobile */
    padding: 20px;
    margin: 0 10px; /* Add gap on left and right */
    background-color: #dee3e7;
}

.sap55-sap-text {
    padding: 0; /* Remove right padding for mobile */
    text-align: center; /* Center text for better readability */
}

.sap55-sap-heading {
    color: #007bff;
    font-size: 2rem;
    margin-top: 20px; /* Add top margin for spacing on mobile */
}

.sap55-sap-description {
    margin-top: 10px;
    line-height: 1.6;
}

.sap55-sap-image {
    margin-bottom: 20px; /* Add space below image */
}

.sap55-sap-img {
    width: 100%;
    height: auto;
    border-radius: 5px;
}

/* Media Query for Larger Screens */
@media (min-width: 768px) {
    .sap55-sap-section {
        flex-direction: row; /* Stack items horizontally on larger screens */
    }

    .sap55-sap-text {
        padding-right: 20px; /* Add padding back for larger screens */
    }

    .sap55-sap-image {
        margin-bottom: 0; /* Remove bottom margin for larger screens */
    }
}


/* Implementation Services Section */
.sap55-implementation-section {
    padding: 20px;
    margin: 0 10px; /* Add gap on left and right */
}

.sap55-implementation-heading {
    font-size: 2rem;
    margin-bottom: 30px;
    text-align: center; /* Center align the heading */
}

.sap55-blue {
    color: #007bff;
}

.sap55-card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center; /* Center the cards horizontally */
    margin: 0; /* Remove margin for mobile view */
}

.sap55-card {
    flex: 1 1 300px; /* Set a base width for cards */
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    margin: 10px; /* Reduce margin for better spacing on mobile */
}

.sap55-card-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.sap55-card-text {
    padding: 10px;
}

.sap55-card-heading {
    font-size: 1.5rem;
    margin: 10px 0;
}

.sap55-card-description {
    font-size: 1rem;
}

/* Media Query for Mobile View */
@media (max-width: 768px) {
    .sap55-implementation-heading {
        font-size: 1.5rem; /* Adjust font size for mobile */
        margin-left: 0; /* Remove left margin */
    }

    .sap55-card {
        flex: 1 1 100%; /* Make cards take full width on mobile */
        margin: 10px 0; /* Adjust margin for mobile */
    }
}






/* Expert Teams Section */
.sap55-expert-teams {
    padding: 20px;
    margin: 0 10px; /* Add gap on left and right */
}

/* Heading for Expert Teams Section */
.sap55-expert-heading {
    font-size: 1.2rem;
    margin-bottom: 20px;
    text-align: center; /* Center the heading */
}

/* Expert Cards Section */
.sap55-expert-cards {
    display: flex;
    flex-wrap: wrap; /* Allow cards to wrap to a new row */
    gap: 30px;
    margin: 70px;
}

/* General Style for All Cards */
.sap55-expert-card {
    flex: 1 1 calc(33.33% - 20px); /* Each card takes 1/3 of the row width minus the gap */
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add subtle shadow for depth */
    background-color: #fff;
    height: 350px;
}

/* Image Style for All Cards */
.sap55-expert-card-img {
    width: 30%;
    height: 100px;
    object-fit: cover;
    margin: 60px;
    margin-left: 120px;
    margin-top: 20px;
}

/* Text Content Style for All Cards */
.sap55-expert-card-text {
    padding: 10px;
    margin-left: 20px;
}

/* Heading Style for All Cards */
.sap55-expert-card-heading {
    font-size: 1.8rem;
    margin: 10px 0;
    margin-left: 30px;
    margin-top: -20px;
}

/* Description Style for All Cards */
.sap55-expert-card-description {
    font-size: 1rem;
    line-height: 1.5;
}

/* Responsive Styles for Mobile */
@media (max-width: 768px) {
    .sap55-expert-cards {
        flex-direction: column; /* Stack cards vertically on mobile */
        gap: 20px;
        margin: 20px; /* Reduce margin for mobile view */
    }

    .sap55-expert-card {
        flex: 1 1 100%; /* Each card takes full width on mobile */
        height: auto; /* Adjust height automatically based on content */
    }

    .sap55-expert-card-img {
        width: 100%; /* Image takes full width of the card on mobile */
        height: auto; /* Adjust image height automatically on mobile */
        margin: 0; /* Remove margin for better fit on mobile */
    }

    .sap55-expert-card-text {
        margin-left: 0; /* Remove left margin for mobile view */
        padding: 10px; /* Adjust padding for mobile view */
        text-align: center; /* Center-align text on mobile */
    }

    .sap55-expert-card-heading {
        margin-left: 0; /* Remove left margin on mobile */
        font-size: 1.5rem; /* Slightly smaller heading on mobile */
        margin-top: 10px;
    }
}







/* Methodologies Section */
.sap55-methodologies-section {
    display: flex;
    padding: 20px;
    margin: 0 10px; /* Add gap on left and right */
}

/* Methodologies Image */
.sap55-methodologies-img {
    flex: 1;
}

.sap55-methodologies-img img {
    width: 100%;
    height: auto;
}

/* Methodologies Text */
.sap55-methodologies-text {
    flex: 1;
    padding-left: 20px;
}

/* Methodologies Heading */
.sap55-methodologies-heading {
    font-size: 2rem;
    color: #007bff;
}

/* Methodologies Description */
.sap55-methodologies-description {
    margin-top: 10px;
    line-height: 1.6;
}

/* Contact Link */
.sap55-contact-link {
    display: inline-block;
    margin-top: 20px;
    color: #007bff;
    text-decoration: none;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
    .sap55-methodologies-section {
        flex-direction: column; /* Stack image and text vertically */
        padding: 10px; /* Adjust padding for mobile view */
        margin: 10px; /* Adjust margin for mobile view */
    }

    .sap55-methodologies-img {
        margin-bottom: 20px; /* Add space between image and text */
    }

    .sap55-methodologies-text {
        padding-left: 0; /* Remove left padding for mobile view */
    }

    .sap55-methodologies-heading {
        text-align: center; /* Center-align heading on mobile */
        font-size: 1.8rem; /* Slightly smaller font size on mobile */
    }

    .sap55-methodologies-description {
        text-align: justify; /* Justify text for better readability on mobile */
    }
}





/* tghuiotehjrrhjtroijtrohiithi */



@media (max-width: 768px) {
    .sap55-banner {
     width: 400px;
     height: 300px;
     margin-left: 15px;
     /* margin-right: 10px; */
    }
}



@media (max-width: 768px) {
    .sap55-logo-img
     {
 margin-left: -30px;
 width: 170px;
 height: 250px;
    }
}

@media (max-width: 768px) {
    .sap55-text-content
     {
 margin-left: 18px;

    }
}