/* ==================== General Styles ==================== */
.app21-container {
    padding: 20px;
    background-color: #f5f5f5;
    font-family: Arial, sans-serif;
  }
  
  /* ==================== Home Image Section Styles ==================== */
  .app21-home-img {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .app21-home-img-src {
    width: 100%;
    height: auto;
    max-height: 500px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  /* ==================== Main Heading Section Styles ==================== */
  .app21-heading-section {
    text-align: center;
    margin-bottom: 40px;
    padding: 0 20px;
  }
  
  .app21-heading {
    font-size: 32px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    line-height: 1.2;
  }
  
  .app21-blue-text {
    color: #007bff;
  }
  
  .app21-subtext {
    font-size: 18px;
    color: #555;
    line-height: 1.6;
  }
  
  /* ==================== Digital Transformation Services Section Styles ==================== */
  .app21-services-section {
    margin-bottom: 60px;
    padding: 0 20px;
  }
  
  .app21-services-heading {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .app21-services-subtext {
    text-align: center;
    font-size: 18px;
    color: #555;
    margin-bottom: 40px;
  }
  
  /* ==================== Services Cards Styles ==================== */
  .app21-cards {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .app21-card {
    background-color: #fff;
    flex: 1;
    min-width: 280px;
    max-width: 32%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .app21-card:hover {
    transform: translateY(-10px);
  }
  
  .app21-card-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .app21-card-heading {
    font-size: 22px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
  }
  
  .app21-card-description {
    font-size: 16px;
    color: #555;
    line-height: 1.5;
    text-align: left;
  }
  
  /* ==================== Why Partner with Automatic Section Styles ==================== */
  .app21-why-partner-section {
    padding: 0 20px;
    margin-bottom: 40px;
  }
  
  .app21-why-heading {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    color: #333;
    margin-bottom: 30px;
    line-height: 1.2;
  }
  
  .app21-why-list {
    list-style: none;
    padding: 0;
    font-size: 18px;
    color: #555;
    line-height: 1.6;
  }
  
  .app21-why-list li {
    margin-bottom: 20px;
    position: relative;
    padding-left: 30px;
  }
  
  .app21-why-list li::before {
    content: "★";
    position: absolute;
    left: 0;
    top: 0;
    color: #007bff;
    font-size: 20px;
    line-height: 1;
  }
  
  /* ==================== Responsive Styles ==================== */
  @media (max-width: 1024px) {
    .app21-card {
      max-width: 48%;
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 768px) {
    .app21-heading {
      font-size: 26px;
    }
  
    .app21-subtext,
    .app21-services-subtext,
    .app21-why-list {
      font-size: 16px;
    }
  
    .app21-services-heading,
    .app21-why-heading {
      font-size: 24px;
    }
  
    .app21-card {
      max-width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .app21-heading {
      font-size: 22px;
    }
  
    .app21-card-img {
      height: 150px;
    }
  }
  


  @media (max-width: 1024px) {
    .customers88 {
 margin-left: -15px;
    }
  }

  