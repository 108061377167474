.development-containerSoft {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

/* Section 1 Styling */
.section1Soft {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
}

.image-container1Soft {
    width: 100%;
}

.image1Soft {
    width: 100%;
    height: 600px;
    border-radius: 8px;
}

.text-container1Soft {
    padding: 20px;
    text-align: center;
}

.title1Soft {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.description1Soft,
.additional-info1Soft {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

/* Section 2 Styling */
.section2Soft {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    align-items: center;
}

.text-container2Soft {
    flex: 1;
    padding: 20px;
}

.image-container2Soft {
    flex: 1;
    padding: 20px;
}

.image2Soft {
    width: 100%;
    height: 400px;
    border-radius: 8px;
}

.sub-title2Soft {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.description2Soft,
.additional-info2Soft {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

/* Section 3 Styling */
.section3Soft {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    align-items: center;
    flex-direction: row-reverse;
}

.text-container3Soft {
    flex: 1;
    padding: 20px;
}

.image-container3Soft {
    flex: 1;
    padding: 20px;
}

.image3Soft {
    width: 100%;
    height: 400px;
    border-radius: 8px;
}

.sub-title3Soft {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.description3Soft,
.additional-info3Soft {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

/* Section 4 Styling */
.section4Soft {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    align-items: center;
}

.text-container4Soft {
    flex: 1;
    padding: 20px;
}

.image-container4Soft {
    flex: 1;
    padding: 20px;
}

.image4Soft {
    width: 100%;
    height: 400px;
    border-radius: 8px;
}

.sub-title4Soft {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.description4Soft,
.additional-info4Soft {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

.blueSoft {
    color: #007bff;
}

/* Responsive Design */
@media (max-width: 768px) {
    .section2Soft,
    .section3Soft,
    .section4Soft {
        flex-direction: column;
    }
}


@media (max-width: 768px) {
    .image1Soft {
      height: 350px; /* Further lower the height for smaller screens */
    }}
