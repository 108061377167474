.java-containerjava22 {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

/* Section 1 Styling */
.section1java22 {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    margin-bottom: 40px;
}

.image-container1java22 {
    width: 100%;
}

.image1java22 {
    width: 100%;
    height: 650px;
    border-radius: 8px;
}

.text-container1java22 {
    padding: 20px;
    text-align: center;
}

.title1java22 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.description1java22,
.additional-info1java22 {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

/* Section 2 Styling */
.section2java22 {
    display: flex;
    flex-direction: column; /* Stack text and image vertically */
    align-items: center;
    margin-bottom: 40px;
}

.text-container2java22,
.image-container2java22 {
    width: 100%;
    padding: 20px;
}

.image2java22 {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.sub-title2java22 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.description2java22,
.additional-info2java22 {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

/* Section 3 Styling */
.section3java22 {
    display: flex;
    flex-direction: column-reverse; /* Stack text below image */
    align-items: center;
    margin-bottom: 40px;
}

.text-container3java22,
.image-container3java22 {
    width: 100%;
    padding: 20px;
}

.image3java22 {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.sub-title3java22 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.description3java22,
.additional-info3java22 {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

/* Section 4 Styling */
.section4java22 {
    display: flex;
    flex-direction: column; /* Stack text and image vertically */
    align-items: center;
    margin-bottom: 40px;
}

.text-container4java22,
.image-container4java22 {
    width: 100%;
    padding: 20px;
}

.image4java22 {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.sub-title4java22 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.description4java22,
.additional-info4java22 {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

.bluejava22 {
    color: #007BFF;
}

/* Media Queries for Responsiveness */
@media (min-width: 768px) {
    .section2java22,
    .section3java22,
    .section4java22 {
        flex-direction: row; /* Side-by-side on larger screens */
    }

    .section3java22 {
        flex-direction: row-reverse; /* Reverse order for section 3 */
    }

    .text-container2java22,
    .image-container2java22,
    .text-container3java22,
    .image-container3java22,
    .text-container4java22,
    .image-container4java22 {
        flex: 1;
    }
}

@media (max-width: 767px) {
    .image1java22 {
      height: 350px; /* Further lower the height for smaller screens */
    }}




