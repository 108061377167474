.fullstack-container-stack {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

/* Section 1 Styling */
.section1-stack {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
}

.image-container1-stack {
    width: 100%;
}

.image1-stack {
    width: 100%;
    height: 500px;
    border-radius: 8px;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.image1-stack:hover {
    transform: scale(1.05);
}

.text-container1-stack {
    padding: 20px;
    text-align: center;
}

.title1-stack {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.description1-stack,
.additional-info1-stack {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

/* Section 2 Styling */
.section2-stack {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    align-items: center;
}

.text-container2-stack {
    flex: 1;
    padding: 20px;
}

.image-container2-stack {
    flex: 1;
    padding: 20px;
}

.image2-stack {
    width: 100%;
    height: auto;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.image2-stack:hover {
    transform: scale(1.05);
}

.sub-title2-stack {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.description2-stack,
.additional-info2-stack {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

/* Section 3 Styling */
.section3-stack {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    align-items: center;
}

.text-container3-stack {
    flex: 1;
    padding: 20px;
}

.image-container3-stack {
    flex: 1;
    padding: 20px;
}

.image3-stack {
    width: 100%;
    height: auto;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.image3-stack:hover {
    transform: scale(1.05);
}

.sub-title3-stack {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.description3-stack {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

.tech-list-stack {
    list-style: none;
    padding: 0;
}

.tech-item-stack {
    font-size: 1rem;
    background: #f0f0f0;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.tech-item-stack:hover {
    background: #d0d0d0;
}

/* Section 4 Styling */
.section4-stack {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    align-items: center;
}

.text-container4-stack {
    flex: 1;
    padding: 20px;
}

.image-container4-stack {
    flex: 1;
    padding: 20px;
}

.image4-stack {
    width: 100%;
    height: auto;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.image4-stack:hover {
    transform: scale(1.05);
}

.sub-title4-stack {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.description4-stack,
.additional-info4-stack {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

.blue-stack {
    color: #007bff;
}

/* Responsive Design */
@media (max-width: 768px) {
    .section1-stack,
    .section2-stack,
    .section3-stack,
    .section4-stack {
        flex-direction: column;
    }
}




@media (max-width: 768px) {
    .image1-stack {
      height: 350px; /* Further lower the height for smaller screens */
    }


    .title1-stack {
        font-size: 2rem;
    }
}