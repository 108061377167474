/* General Styles */
.header-containerDevH {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  position: relative;
  z-index: 1000;
  max-height: 100px;
  font-family: 'cursive', sans-serif;
  font-weight: 500;
  height: 70px;
}

.logo-containerDevH {
  flex: 1;
 
}

.logoDevH {
  max-height: 144px;
  border-radius: 5px;
  width: 220px;
  margin-top: -10px;
}

.navDevH {
  flex: 3;
  display: flex;
  justify-content: flex-end;
}

.nav-listDevH {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-itemDevH {
  position: relative;
  margin: 0 15px;
}

.nav-linkDevH {
  color: #000;
  text-decoration: none;
  padding: 10px 15px;
  display: block;
  transition: background-color 0.3s ease;
  font-family: 'cursive', sans-serif;
  font-weight: 500;
}

.nav-linkDevH:hover {
  background-color: #9799a1;
  border-radius: 10px;
}

.dropdownDevH {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 200px;
}

.nav-itemDevH:hover .dropdownDevH {
  display: block;
}

.dropdown-itemDevH {
  padding: 10px 15px;
}

.dropdown-itemDevH12 {
  padding: 10px 5px;
 
}





.dropdown-itemDevH a {
  color: #000;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s ease;
}

.dropdown-itemDevH a:hover {
  background-color: #f0f0f0;
}

/* Sidebar Styles for Mobile View */
@media (max-width: 768px) {
  .navDevH {
    position: fixed;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    background-color: #fff;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    visibility: hidden;
    opacity: 0;
    z-index: 1001;
  }

  .nav-listDevH {
    flex-direction: column;
    margin-top: 60px;
  }

  .nav-itemDevH {
    margin: 0;
    width: 100%;
  }

  .nav-linkDevH {
    padding: 15px;
    border-bottom: 1px solid #444;
    color: #000;
  }

  .dropdownDevH {
    position: relative;
    top: 0;
    width: 100%;
    box-shadow: none;
  }

  .header-containerDevH.active .navDevH {
    transform: translateX(0);
    visibility: visible;
    opacity: 1;
  }

  .hamburgerDevH {
    display: block;
    cursor: pointer;
    padding: 15px;
  }

  .hamburger-lineDevH {
    width: 25px;
    height: 3px;
    background-color: #000;
    margin: 5px 0;
  }

  .cancel-iconDevH {
    font-size: 30px;
    color: #000;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

  .header-containerDevH.active .navDevH {
    display: block;
  }
}

/* Hides Sidebar for Desktop */
@media (min-width: 769px) {
  .navDevH {
    display: flex;
    position: static;
    transform: none;
    visibility: visible;
    opacity: 1;
    flex-direction: row;
  }

  .hamburgerDevH {
    display: none;
  }

  .cancel-iconDevH {
    display: none;
  }
}
