.about-containerAbout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .about-text-sectionAbout {
    width: 50%;
    padding-right: 20px;
  }
  
  .about-titleAbout {
    font-size: 35px;
    font-weight: bold;
    color: #000;
    margin-bottom: 20px;
  }
  
  .about-highlightAbout {
    color: #007BFF; /* Blue color for "Technology" */
  }
  
  .about-descriptionAbout,
  .about-subtextAbout {
    font-size: 16px;
    color: #333;
    margin-bottom: 15px;
    line-height: 1.6;
  }
  
  .about-read-moreAbout {
    background-color: #007BFF; /* Blue button */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .about-image-sectionAbout {
    width: 50%;
    padding-left: 20px;
  }
  
  .about-imageAbout {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
  
  @media (max-width: 768px) {
    .about-containerAbout {
      flex-direction: column;
      align-items: center;
    }
  
    .about-text-sectionAbout,
    .about-image-sectionAbout {
      width: 100%;
      padding: 0;
    }
  
    .about-imageAbout {
      margin-top: 20px;
    }
  }
  