.transformation-containerTrans {
  padding: 40px;
  text-align: center;
}

.headingTrans {
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 20px;
}

.heading-mainTrans {
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 10px;
}

.heading-subTrans {
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 40px;
  color: #0056b3;
}

.black-textTrans, .blue-textTrans {
  font-size: 40px;
  /* margin-bottom: 20px; */
}

.card-containerTrans {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.cardTrans {
  width: 45%;
  height: 450px;
  transition: width 0.3s ease;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.cardTrans:not(.activeTrans) {
  width: 22%;
}

.card-imageTrans {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.card-titleTrans, .know-more-btnTrans {
  position: absolute;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
}

.card-titleTrans {
  bottom: 25%;
}

.know-more-btnTrans {
  bottom: 10%;
  background-color: #007BFF;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.know-more-btnTrans:hover {
  background-color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .black-textTrans, .blue-textTrans {
    font-size: 40px; /* Slightly smaller text on large tablets */
  }
}

@media (max-width: 992px) {
  .black-textTrans, .blue-textTrans {
    font-size: 35px; /* Smaller text on tablets */
  }
}

@media (max-width: 768px) {
  .card-containerTrans {
    flex-direction: column;
    gap: 15px; /* Reduced gap between cards on mobile */
  }

  .cardTrans {
    width: 100%;
    height: 350px; /* Adjust height for mobile */
  }

  .cardTrans:not(.activeTrans) {
    width: 100%;
  }

  .card-titleTrans {
    bottom: 20%; /* Adjust title position for mobile */
  }

  .know-more-btnTrans {
    bottom: 8%; /* Adjust button position for mobile */
  }

  .black-textTrans, .blue-textTrans {
    font-size: 30px; /* Smaller text on mobile */
  }

  .heading-mainTrans, .heading-subTrans {
    font-size: 28px; /* Smaller heading text on mobile */
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .black-textTrans, .blue-textTrans {
    font-size: 25px; /* Even smaller text on very small screens */
  }

  .cardTrans {
    height: 300px; /* Further reduced height on very small screens */
  }

  .heading-mainTrans, .heading-subTrans {
    font-size: 24px; /* Smaller heading text on very small screens */
  }
}
