
/* Statistics Section */
.sap55-stats {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #f0f0f0;

}

.sap55-stat-item {
    text-align: center;
    flex: 1;
    margin: 0 10px;
}

.sap55-stat-number {
    font-size: 2rem;
    font-weight: bold;
}

.sap55-stat-description {
    font-size: 1rem;
    color: #555;
}

.sap55-divider {
    width: 100%;
    height: 4px;
    background-color: #007bff;
    margin: 10px 0;
    margin-top: -5px;
}